import styled from 'styled-components';

import { red } from 'styles/colors';

export const Required = styled.span`
    &:before {
        font-size: 1.25em;
        content: '*';
        color: ${red};
    }
`;
