export enum ValidationRule {
    isRequired = 'isRequired',
    isEmail = 'isEmail',
    isTrue = 'isTrue',
    isPDF = 'isPDF',
}

export interface FormControl {
    value: string | boolean | File | null;
    touched: boolean;
    valid: boolean;
    validationRules: ValidationRule[];
    showRequiredStar?: boolean;
    errorMessage?: string;
    name?: string;
}

export interface FormControls {
    [key: string]: FormControl;
}
