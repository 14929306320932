import { ValidationRule, FormControls, FormControl } from '../types/form';
import { validationRuleMessages } from '../config/validationRuleMessages';
import { clone } from 'ramda';

const requiredValidator = (value: string | File | boolean | null): boolean =>
    value !== null && value.toString().trim() !== '';

const emailValidator = (value: string | File | boolean | null): boolean =>
    value !== null &&
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value.toString().trim(),
    );

const trueValidator = (value: string | File | boolean | null): boolean => value !== null && value === true;

const pdfValidator = (value: string | File | boolean | null): boolean => {
    const file = value as File;
    if (file) {
        return file.type === 'application/pdf';
    }
    return true;
};

export const validateFormElement = (
    value: string | File | boolean | null,
    validationRules: ValidationRule[],
): [boolean, string] => {
    let isValid = true;
    let errorValidation: string;

    validationRules.forEach((rule) => {
        const isValidBeforeThisRule = isValid;
        switch (rule) {
            case ValidationRule.isRequired:
                isValid = isValid && requiredValidator(value);
                break;
            case ValidationRule.isEmail:
                isValid = isValid && emailValidator(value);
                break;
            case ValidationRule.isTrue:
                isValid = isValid && trueValidator(value);
                break;
            case ValidationRule.isPDF:
                isValid = isValid && pdfValidator(value);
                break;
            default:
                isValid = true;
                break;
        }

        if (!isValid && isValidBeforeThisRule) {
            errorValidation = validationRuleMessages[rule];
        }
    });

    return [isValid, errorValidation];
};

export const isFormControlRequired = (formControl: FormControl): boolean => {
    return formControl.validationRules.indexOf(ValidationRule.isRequired) !== -1;
};

export const createFormControlsCopy = (formControls: FormControls): FormControls => clone(formControls);

export const createFormControlCopy = (key: string, formControls: FormControls): FormControl => clone(formControls[key]);

export const getValueFromTarget = (target: EventTarget & HTMLInputElement): string | boolean | File => {
    switch (target.type) {
        case 'checkbox':
            return target.checked;
        case 'file':
            return target.files[0];
        default:
            return target.value;
    }
};

export const setValidationFormControl = (formControl: FormControl): void => {
    const [isValid, validationError] = validateFormElement(formControl.value, formControl.validationRules);

    formControl.valid = isValid;
    formControl.errorMessage = validationError;
};

export const changeHandler = (
    event: React.FormEvent<HTMLInputElement>,
    formControls: FormControls,
    setFormControls: (formControls: FormControls) => void,
): void => {
    const currentTarget = event.currentTarget;
    const name = currentTarget.name;

    const value: string | boolean | File = getValueFromTarget(currentTarget);

    const updatedControls = createFormControlsCopy(formControls);
    const updatedFormElement = createFormControlCopy(name, formControls);

    updatedFormElement.value = value;

    updatedFormElement.touched = true;

    setValidationFormControl(updatedFormElement);

    updatedControls[name] = updatedFormElement;

    setFormControls(updatedControls);
};

// checks if all passed formControls are valid, returns boolean.
export const checkIfFormValid = (formControls: FormControls): boolean => {
    let valid = true;
    Object.keys(formControls).map(function (key) {
        const control: FormControl = formControls[key];
        if (!control.valid) {
            valid = false;
        }
    });
    return valid;
};

// marks all controls touched to the users sees feedback
export const validateForm = (
    formControls: FormControls,
    setFormControls: (formControls: FormControls) => void,
): any => {
    const updatedControls = createFormControlsCopy(formControls);
    let valid = true;

    const updatedControlsArray = Object.keys(updatedControls).map(function (key) {
        const control: FormControl = updatedControls[key];
        setValidationFormControl(control);
        control.touched = true;
        if (!control.valid) {
            valid = false;
        }
        control.name = key;
        return control;
    });

    setFormControls(updatedControls);

    return {
        valid: valid,
        formControls: updatedControlsArray,
    };
};
