import React from 'react';

import { FormControl } from 'types/form';
import { isFormControlRequired } from 'utils/form';
import { existy } from 'utils/functional';

import { FormGroupProps, InputProps, FormGroup, Label, Input, FormFeedback, CustomInput } from 'reactstrap';
import { Required } from './atoms/Required';

interface FormGroupWithKeyProps extends FormGroupProps {
    name: string;
    changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    formControl: FormControl;
    label: string;
    inputProps?: InputProps;
    disabled?: boolean;
}
export const FormGroupWithKey: React.FC<FormGroupWithKeyProps> = ({
    name,
    changeHandler,
    formControl,
    inputProps,
    children,
    label,
    disabled,
    ...props
}) => {
    const isCheckbox = existy(inputProps) && inputProps.type === 'checkbox';
    return (
        <FormGroup disabled={disabled} {...(props as { [key: string]: any })}>
            {!isCheckbox && (
                <Label for={name}>
                    {isFormControlRequired(formControl) && formControl.showRequiredStar && <Required />} {label}
                </Label>
            )}

            {isCheckbox ? (
                <CustomInput
                    type="checkbox"
                    invalid={!formControl.valid && formControl.touched}
                    onChange={changeHandler}
                    name={name}
                    id={name}
                    label={label}
                    disabled={disabled}
                />
            ) : (
                <Input
                    invalid={!formControl.valid && formControl.touched}
                    onChange={changeHandler}
                    name={name}
                    id={name}
                    {...inputProps}
                    disabled={disabled}
                >
                    {children}
                </Input>
            )}

            {existy(formControl.errorMessage) && <FormFeedback>{formControl.errorMessage}</FormFeedback>}
        </FormGroup>
    );
};
