import styled from 'styled-components';

import { FontAwesomeBrand, FontAwesomeFree } from 'styles/fonts';

interface Props {
    brand?: boolean;
    flip?: boolean;
    content: string;
}
export const Icon = styled.strong<Props>`
  &:before {
    font-family: "${({ brand }): string => (brand ? FontAwesomeBrand : FontAwesomeFree)}";
    margin-right: 8px;
    content: "${({ content }): string => content}";

    ${({ flip }): string =>
        flip &&
        `
      transform: rotate(90deg);
      display: inline-block;
    `}
  }
`;
